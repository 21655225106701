import { Outlet } from "react-router-dom";

import { HdLoader } from "@hd/ui-kit";
import { useQueryClient } from "@tanstack/react-query";

import { meQueryKey, useMeQuery } from "../../api/auth/useMeQuery";
import { useAuth } from "../auth/auth.store";
import ContactPanel from "../contact-panel/ContactPanel";
import WhatsappModal from "../whatsapp-modal/WhatsappModal";
import Footer from "./footer/Footer";
import Header from "./header/Header";
import Sidebar from "./sidebar/Sidebar";

function Layout() {
  const { data, isPending } = useMeQuery();

  const logout = useAuth((state) => state.logout);
  const changeGroup = useAuth((state) => state.changeGroup);
  // const userId = useAuth((state) => state.loggedUserData!.userId);
  // const username = useAuth((state) => state.loggedUserData!.username);
  // const activeCompanies = useAuth(
  //   (state) => state.loggedUserData!.activeCompanies
  // );
  // const activeGroupName = useAuth(
  //   (state) => state.loggedUserData!.activeGroupName
  // );
  // const activeGroups = useAuth((state) => state.loggedUserData!.activeGroups);
  // const sections = useAuth((state) => state.loggedUserData!.sections);
  // const editUsersAndPermissions = useAuth(
  //   (state) => state.loggedUserData!.editUsersAndPermissions
  // );

  const queryClient = useQueryClient();

  return isPending || !data ? (
    <HdLoader></HdLoader>
  ) : (
    <div className="flex flex-col h-full flex-grow">
      <Header
        username={data.username}
        userId={data.userId}
        activeCompanies={data.activeCompanies}
        activeGroupName={data.activeGroupName}
        activeGroups={data.activeGroups}
        editUsersAndPermissions={data.editUsersAndPermissions}
        logout={async () => {
          await logout();

          queryClient.invalidateQueries({
            queryKey: meQueryKey(),
          });
        }}
        changeGroup={changeGroup}
      />
      <div className="flex flex-grow relative">
        <Sidebar items={data.sections} />
        <div className="flex-grow bg-bodyBg flex flex-col">
          <Outlet></Outlet>
        </div>
      </div>
      <Footer></Footer>
      <ContactPanel></ContactPanel>
      <WhatsappModal></WhatsappModal>
    </div>
  );
}

export default Layout;
