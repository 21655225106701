import { useState } from "react";
import { MdClose } from "react-icons/md";

import { useI18n } from "@hd/i18n";
import { showErrorFeedback } from "@hd/toast";
import { HdButton, HdLoader, HdModal, HdRadioGroup } from "@hd/ui-kit";

import { useGetWhatsappMessageLinkMutation } from "../../api/contacts/mutations/useGetWhatsappMessageLinkMutation";
import { useContactPhoneNumbersQuery } from "../../api/contacts/query/useContactPhoneNumbersQuery";
import { useWhatsappModal } from "./whatsapp-modal.store";

function WhatsappModal() {
  const contactId = useWhatsappModal((state) => state.contactId);
  const isOpen = useWhatsappModal((state) => state.isOpen);
  const quotationId = useWhatsappModal((state) => state.quotationId);
  const closeModal = useWhatsappModal((state) => state.close);
  const mutation = useGetWhatsappMessageLinkMutation();

  const { data, isPending } = useContactPhoneNumbersQuery({ contactId });

  const [selectedNumber, setSelectedNumber] = useState<string | null>(null);
  const t = useI18n();

  return (
    <HdModal
      backdrop={true}
      isOpen={isOpen}
      onOpenChange={(isOpen) => {
        if (!isOpen) {
          closeModal();
        }
      }}
      // closeOnClickOutside={false}
      className="p-2 bg-white w-[400px]  flex flex-col"
    >
      <div className="bg-aqua text-white flex p-4 items-center">
        <div className="text-md font-semibold">{t("whatsappModal.title")}</div>
        <div className="ml-auto cursor-pointer" onClick={() => closeModal()}>
          <MdClose></MdClose>
        </div>
      </div>
      <div className="grow text-md px-3 my-3">
        <HdRadioGroup onChange={setSelectedNumber} selected={selectedNumber}>
          {isPending || !data ? (
            <HdLoader></HdLoader>
          ) : (
            data?.map((phoneNumber) => (
              <HdRadioGroup.Radio
                // className={
                //   "group relative flex cursor-pointer rounded-sm bg-white/5 py-4 px-5 text-sm shadow-md transition focus:outline-none"
                // }
                key={phoneNumber}
                value={phoneNumber}
                label={phoneNumber}
              ></HdRadioGroup.Radio>
            ))
          )}
        </HdRadioGroup>
      </div>
      <div className="flex items-center justify-end">
        <HdButton
          variant="secondary"
          type="button"
          size="small"
          onClick={() => closeModal()}
        >
          {t("common.close")}
        </HdButton>
        <HdButton
          variant="primary"
          type="button"
          size="small"
          className="ml-2"
          disabled={!selectedNumber}
          onClick={() => {
            mutation.mutate(
              { phoneNumber: selectedNumber as string, quotationId },
              {
                onSuccess: (_result) => {
                  window.open(_result, "_blank");
                  closeModal();
                },
                onError: (err) => {
                  showErrorFeedback(err.message);
                },
              }
            );
          }}
        >
          {t("common.send")}
        </HdButton>
      </div>
    </HdModal>
  );
}

export default WhatsappModal;
