import { useCallback } from "react";
import toast, { Toast, ToastBar, Toaster } from "react-hot-toast";

import { useNotifications } from "../../core/notifications/notifications.store";

function ToastWrapper() {
  const { setOpen } = useNotifications();

  const dimissToast = useCallback(
    (t: Toast) => {
      toast.dismiss(t.id);

      if (t.className?.includes("notification-toast")) {
        setOpen(true);
      }
    },
    [setOpen]
  );
  return (
    <Toaster>
      {(t) => (
        <ToastBar toast={t}>
          {({ icon, message }) => (
            <div
              className="flex text-center w-full items-center text-sm"
              onClick={() => dimissToast(t)}
            >
              {icon}
              {message}
            </div>
          )}
        </ToastBar>
      )}
    </Toaster>
  );
}

export default ToastWrapper;
