// /* eslint-disable @typescript-eslint/no-explicit-any */
// import { t } from "i18next";

import { useTranslation } from "react-i18next";

// export function translate(key: string, opts: any) {
//   return t(key, opts);
// }

export function useI18n() {
  const { t } = useTranslation();
  return t;
}
