import { create } from "zustand";

import { showNewNotification } from "@hd/toast";

import { SignalrChatMessage } from "../../types/model/shared/signalr-chat-message";

export interface Notification {
  type: "message" | "connection-info";
  content: SignalrChatMessage;
  date: Date;
  read: boolean;
  companyName: string;
  companyId: number;
}

export interface NotificationsState {
  open: boolean;
  items: Notification[];
  companyId: number | null;
  push: (notification: Notification) => void;
  toggleOpen: () => void;
  setOpen: (value: boolean) => void;
  setReadAll: () => void;
  setRead: (date: Date) => void;
  setCompanyId(companyId: number): void;
}

const showNotification = (notification: Notification) => {
  if (notification.type === "message") {
    const content = notification.content as SignalrChatMessage;
    showNewNotification("Nuovo messaggio da " + content.SenderFullName);
  }
};

export const useNotifications = create<NotificationsState>((set) => {
  return {
    open: false,
    items: [],
    companyId: null,

    push: (notification: Notification) => {
      set((state) => ({
        items: [...state.items, notification],
      }));

      showNotification(notification);
    },

    setReadAll: () => {
      set((state) => ({
        items: state.items.map((item) => ({ ...item, read: true })),
      }));
    },

    setRead: (date: Date) => {
      set((state) => ({
        items: state.items.map((item) =>
          item.date === date ? { ...item, read: true } : item
        ),
      }));
    },

    toggleOpen: () => {
      set((state) => ({
        open: !state.open,
      }));
    },
    setOpen: (value: boolean) => {
      set(() => ({
        open: value,
      }));
    },

    setCompanyId: (companyId: number) => {
      set(() => ({
        companyId,
      }));
    },
  };
});

// export const useNotifications = () => {
//   const { open, toggleOpen, setOpen, items, setRead, setReadAll, push } =
//     useNotificationsStore();

//   return {
//     open,
//     items,
//     push,
//     setReadAll,
//     setRead,
//     toggleOpen,
//     setOpen,
//     itemsToReadCount: useNotificationsStore(
//       (state) => state.items.filter((item) => !item.read).length
//     ),
//   };
// };
