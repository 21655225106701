import { createElement } from "react";
import toast from "react-hot-toast";

export const showNewNotification = (message: string) => {
  toast(message, {
    position: "bottom-right",
    duration: 5000,
    icon: createElement("i", { className: "fa fa-envelope" }),
    className: "notification-toast cursor-pointer h-16 w-72 hover:!opacity-90",
  });
};

export const showOnlinePersonNotification = (message: string) => {
  toast(message, {
    position: "bottom-left",
    duration: 5000,
    icon: undefined,
    className: "info-toast h-16 w-72 bg-accent text-white hover:!opacity-90",
  });
};

export const showErrorFeedback = (message: string) => {
  toast.error(message, {
    position: "bottom-right",
    duration: 20000,
    className: "error-toast cursor-pointer h-16 w-72 hover:!opacity-90",
  });
};

export const showSuccessFeedback = (message: string) => {
  toast.success(message, {
    position: "bottom-right",
    duration: 2000,
    className: "success-toast h-16 w-72 bg-primary hover:!opacity-90",
  });
};
