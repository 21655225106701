import { httpClient } from "@hd/http-client";
// export async function getWhatsappMessageLink(
//   phoneNumber: string,
//   quotationId: number | null
// ) {
//   const result = await httpClient.post<string>(
//     import.meta.env.VITE_HD_API_URL + `/contact/get-whatsapp-message-link`,
//     {
//       phoneNumber,
//       quotationId,
//     }
//   );
//   return result.data;
// }
import { useMutation } from "@tanstack/react-query";

interface Params {
  phoneNumber: string;
  quotationId: string | null;
}

const request = async (params: Params) => {
  const result = await httpClient.post<string>(
    import.meta.env.VITE_HD_API_URL + `/contact/get-whatsapp-message-link`,
    params
  );

  return result.data;
};

export const useGetWhatsappMessageLinkMutation = () => {
  return useMutation({
    mutationFn: (data: Params) => request(data),
  });
};
