import { httpClient, SILENT_REQUEST_HEADER_KEY } from '@hd/http-client';
import { useQuery } from '@tanstack/react-query';

import { ContactDataDto } from '../../../types/dto/contact/contact-data.dto';

type QueryParams = {
  contactId: number;
};

const apiUrl = (params: QueryParams) => `/contact/${params.contactId}/`;

const fetchData = async (params: QueryParams): Promise<ContactDataDto> => {
  const result = await httpClient.get<ContactDataDto>(
    import.meta.env.VITE_HD_API_URL + apiUrl(params),
    { headers: { [SILENT_REQUEST_HEADER_KEY]: "true" } }
  );

  return result.data;
};

export const contactDetailsQueryKey = (params: QueryParams) => [apiUrl(params)];

export const useContactDetailsQuery = (params: QueryParams) => {
  return useQuery({
    // staleTime: 10000 * 60,
    queryKey: contactDetailsQueryKey(params),
    queryFn: () => fetchData(params),
    // enabled: !!params.contactId,
  });
};
