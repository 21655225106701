import { formatRelative } from "date-fns";
import { it } from "date-fns/locale";
import { useCallback, useMemo, useRef } from "react";
import { NavLink } from "react-router-dom";
import { useOnClickOutside } from "usehooks-ts";

import { useI18n } from "@hd/i18n";

import { useMeQuery } from "../../api/auth/useMeQuery";
import { SignalrChatMessage } from "../../types/model/shared/signalr-chat-message";
import CompanySelector from "../auth/components/CompanySelector";
import useHubConnection from "../chat/useHubConnection";
import { useContactPanelStore } from "../contact-panel/contact-panel.store";
import { useNotifications } from "./notifications.store";

function Notifications() {
  const ref = useRef<HTMLDivElement>(null);
  // const { items, setRead, setOpen, open, companyId, setCompanyId } =
  //   useNotifications();

  const { data } = useMeQuery();

  const items = useNotifications((s) => s.items);
  const setOpen = useNotifications((s) => s.setOpen);
  const push = useNotifications((s) => s.push);
  const open = useNotifications((s) => s.open);
  const companyId = useNotifications((s) => s.companyId);
  const setCompanyId = useNotifications((s) => s.setCompanyId);

  const openContactPanel = useContactPanelStore((s) => s.open);

  const t = useI18n();

  const onClickOutside = useCallback(
    (e: MouseEvent | TouchEvent | FocusEvent) => {
      if (
        open &&
        !(e.target as HTMLElement).classList.contains("company-option")
      ) {
        setOpen(false);
      }
    },
    [open, setOpen]
  );

  const companyName = useMemo(
    () => data?.activeCompanies.find((c) => c.id === companyId)?.name,
    [data, companyId]
  );

  const onNewMessage = useCallback(
    (data: SignalrChatMessage) => {
      if (companyId && companyName) {
        if (data.UserType === 0) {
          push({
            type: "message",
            content: data,
            date: new Date(),
            read: false,
            companyName: companyName,
            companyId: companyId,
          });
        } else {
          // operator message
          // system message
        }
      }
    },
    [push, companyId, companyName]
  );

  useHubConnection(companyId, data?.username || null, onNewMessage);

  useOnClickOutside(ref, onClickOutside);

  //   useEffect(() => {
  //     if (open) {
  //       ref.current?.focus();
  //     }
  //   }, [open]);

  //   useEffect(() => {
  //     if (hubConnection && companyId && companyName) {
  //       hubConnection.onNewMessage((data) => {
  //         if (data.UserType === 0) {
  //           push({
  //             type: "message",
  //             content: data,
  //             date: new Date(),
  //             read: false,
  //             companyName: companyName,
  //             companyId: companyId,
  //           });
  //         } else {
  //           // operator message
  //           // system message
  //         }
  //       });
  //     }
  //   }, [hubConnection, push, companyId, companyName]);

  //   useEffect(() => {
  //     document.addEventListener("click", onClickOutside, false);
  //     return () => document.removeEventListener("click", onClickOutside);
  //   }, [onClickOutside]);

  //   useEffect(() => {
  //     if (top) {
  //       top.addEventListener("blur", onClickOutside, false);
  //       return () =>
  //         top ? top.removeEventListener("blur", onClickOutside) : undefined;
  //     }
  //   }, [onClickOutside]);

  //   useEffect(() => {
  //     ref.current?.addEventListener("focusout", () => {
  //       if (!ref.current?.matches(":focus-within")) {
  //         setOpen(false);
  //       }
  //     });
  //   }, [setOpen, ref]);

  return (
    <div
      tabIndex={0}
      ref={ref}
      className="select-none align-center shadow-xl bg-white w-64 text-bodyText border-gray-200 border outline-none"
    >
      <div className="px-3 flex items-center border-b-gray-200 border-b py-2">
        <span className="mr-1 text-xs">{t("notifications.chooseCompany")}</span>

        <div className="ml-auto">
          <CompanySelector
            selectedCompanyId={companyId}
            selectedCompanyChanged={(companyId) => setCompanyId(companyId)}
          ></CompanySelector>
        </div>
      </div>

      <ul className="">
        {items.length === 0 ? (
          <div className="py-4 text-center px-3">
            Non sono presenti nuovi messaggi
          </div>
        ) : (
          <>
            {items
              .filter((item) => item.companyId === companyId)
              .sort((a, b) => b.date.getTime() - a.date.getTime())
              .slice(0, 5)
              .map((item) => (
                <li
                  key={item.date.getTime()}
                  className={`py-2 flex items-center justify-between border-b border-b-gray-300 w-full overflow-hidden hover:opacity-80 cursor-pointer even:bg-bodyBg`}
                  onClick={(e) => {
                    e.stopPropagation();
                    const contactId = Number(
                      item.content.GroupName.split("-")[1]
                    );
                    const companyId = Number(
                      item.content.GroupName.split("-")[0]
                    );

                    openContactPanel(contactId, companyId, 0);
                  }}
                >
                  <div className={`px-3 flex items-center w-full `}>
                    {/* <div
                    className={`w-7 h-7 flex-shrink-0 rounded-full flex items-center justify-center mr-3 bg-secondary text-white`}
                  >
                    <i
                      className={`fa ${
                        item.read ? "fa-envelope-open" : "fa-envelope"
                      }`}
                    ></i>
                  </div> */}
                    <div className={` flex-grow overflow-hidden`}>
                      <div className="text-xs font-bold">
                        {item.content.SenderFullName}
                      </div>
                      <div className="text-xs  text-ellipsis text-nowrap overflow-hidden">
                        {item.content.Text}
                      </div>
                      <div className="text-xs font-bold">
                        {formatRelative(item.date, new Date(), {
                          locale: it,
                        })}
                        {" - "}
                        {item.companyName}
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            <div className="py-2 text-center px-3 bg-bodyBg">
              <NavLink className="underline text-xs" to={"/conversation-hub"}>
                Vai all'hub conversazioni
              </NavLink>
            </div>
          </>
        )}
      </ul>
    </div>
  );
}

export default Notifications;
