import { PropsWithChildren, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";

import { useAuth } from "./auth.store";
import { AuthenticationStatus } from "./types/authentication-status";

const loginRoute = "/account/login";

export function PrivateRoute(props: PropsWithChildren) {
  const authStatus = useAuth((s) => s.authStatus);
  const init = useAuth((s) => s.init);
  const navigate = useNavigate();

  useEffect(() => {
    if (authStatus === AuthenticationStatus.Loading) {
      init();
    } else if (authStatus === AuthenticationStatus.Unauthenticated) {
      navigate(loginRoute);
    }
  }, [init, authStatus, navigate]);

  return authStatus === AuthenticationStatus.Loading ? (
    <></>
  ) : (
    <>
      {authStatus === AuthenticationStatus.Authenticated ? (
        props.children
      ) : (
        <Navigate to={loginRoute} />
      )}
    </>
  );
}
