import { create } from "zustand";

import { showErrorFeedback } from "@hd/toast";

import * as AuthApi from "./auth.api";
import { setToken } from "./auth.functions";
import { AuthenticationStatus } from "./types/authentication-status";

interface AuthState {
  authStatus: AuthenticationStatus;
  pageCompanyId: number | null;
  // loggedUserData: {
  //   userId: string;
  //   username: string;
  //   userFullName: string;
  //   sections: SectionItem[];
  //   activeGroupName: string;
  //   activeGroups: ListItemDto[];
  //   activeCompanies: ListItemDto[];
  //   editUsersAndPermissions: boolean;
  // } | null;
  // token: string | null;
  error: string;
  login: (username: string, password: string) => Promise<void>;
  loginSuccess: (token: string) => void;
  loginError: (error: unknown) => void;
  logout: () => Promise<void>;
  sessionExpired: () => Promise<void>;
  init: () => Promise<void>;
  changeGroup: (id: number) => Promise<void>;
  forgotPassword: (email: string) => Promise<boolean>;
  resetPassword: (token: string, newPassword: string) => Promise<boolean>;
  // setToken: (token: string | null) => void;
  setPageCompanyId: (id: number) => void;
}

export const useAuth = create<AuthState>()(
  // persist(
  (set) => {
    const loginSuccess = (token: string) => {
      setToken(token);

      set(() => ({
        authStatus: AuthenticationStatus.Authenticated,
        error: "",
      }));
    };

    const loginError = (error: unknown) => {
      setToken(null);

      set(() => ({
        authStatus: AuthenticationStatus.Unauthenticated,
        error:
          error instanceof Error
            ? error.message
            : "Si è verificato un errore imprevisto",
      }));
    };

    return {
      authStatus: AuthenticationStatus.Loading,
      // token: null,
      // loggedUserData: null,
      error: "",
      pageCompanyId: null,
      // setToken: (token: string | null) => set(() => ({ token })),
      setPageCompanyId: (id: number) => {
        set(() => ({ pageCompanyId: id }));
      },
      init: async () => {
        try {
          const result = await AuthApi.refreshToken();

          setToken(result.jwtToken);

          set(() => ({
            error: "",
          }));

          // workaround: interceptor now are recreated, but just too late to get the new token. So we have to wait
          // await new Promise((resolve) => setTimeout(resolve));

          set(() => ({
            authStatus: AuthenticationStatus.Authenticated,
          }));
        } catch {
          setToken(null);

          set(() => ({
            authStatus: AuthenticationStatus.Unauthenticated,
          }));
        }
      },

      changeGroup: async (id: number) => {
        await AuthApi.changeGroup(id);

        window.location.reload();
      },
      login: async (username: string, password: string) => {
        try {
          const loginResult = await AuthApi.login(username, password);
          loginSuccess(loginResult.jwtToken);
        } catch (error: unknown) {
          loginError(error);
        }
      },
      loginSuccess,
      loginError,
      logout: async () => {
        try {
          await AuthApi.logout();
        } finally {
          set(() => ({
            authStatus: AuthenticationStatus.Unauthenticated,
            error: "",
          }));
        }
      },
      sessionExpired: async () => {
        set(() => ({
          authStatus: AuthenticationStatus.Unauthenticated,
          error: "",
        }));
        showErrorFeedback("Session expired");
      },
      forgotPassword: async (email: string) => {
        try {
          await AuthApi.forgotPassword(email);
          return true;
        } catch (error: unknown) {
          setToken(null);
          set(() => ({
            authStatus: AuthenticationStatus.Unauthenticated,
            error:
              error instanceof Error
                ? error.message
                : "Si è verificato un errore imprevisto",
          }));
          return false;
        }
      },
      resetPassword: async (token: string, newPassword: string) => {
        try {
          await AuthApi.resetPassword(newPassword, token);
          return true;
        } catch (error: unknown) {
          setToken(null);
          set(() => ({
            authStatus: AuthenticationStatus.Unauthenticated,
            error:
              error instanceof Error
                ? error.message
                : "Si è verificato un errore imprevisto",
          }));
          return false;
        }
      },
    };
  }
  // {
  //   name: "auth",
  //   storage: createJSONStorage(() => sessionStorage),
  //   partialize: (state) => ({
  //     token: state.token,
  //   }),
  // }
  // )
);
