import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDebounceCallback } from "usehooks-ts";

import { showErrorFeedback } from "@hd/toast";
import { HdPageLoader } from "@hd/ui-kit";

import { useContactPanelStore } from "../contact-panel/contact-panel.store";
import { useSidebar } from "../layout/sidebar/sidebar.store";
import { useWhatsappModal } from "../whatsapp-modal/whatsapp-modal.store";

interface IFrameMessage {
  kind: string;
}

interface LoadedMessage extends IFrameMessage {
  kind: "loaded";
  url: string;
  height: number;
  title: string;
}

interface OpenContactPanelMessage extends IFrameMessage {
  kind: "showContactPanel";
  contactId: string;
  companyId: string;
  tabIndex: number;
}

interface OpenWhatsappModalMessage extends IFrameMessage {
  kind: "openWhatsappModal";
  contactId: string;
  quotationId: string | null;
}

function LegacyHdBridgePage() {
  const location = useLocation();

  // const setUrl = useLegacyBridge((s) => s.setUrl);
  const [loading, setLoading] = useState(true);
  const [height, setHeight] = useState(0);

  const timeoutTimer = useRef<number | null>(null);

  const { setCurrentUrl: setUrl } = useSidebar();

  const openContactPanel = useContactPanelStore((s) => s.open);
  const openWhatsappModal = useWhatsappModal((s) => s.open);

  // console.log("rendering");

  const onMessageReceived = useCallback(
    (data: IFrameMessage) => {
      if (data.kind === "loading") {
        setLoading(true);
        // if (!loading) {
        //   console.log("loading changed, triggering rendering");
        // } else {
        //   console.log("loading not changed, should not trigger rendering");
        // }
      } else if (data.kind === "loaded") {
        const message = data as LoadedMessage;

        setLoading(false);
        // if (loading) {
        //   console.log("loading changed, triggering rendering");
        // } else {
        //   console.log("loading not changed, should not trigger rendering");
        // }

        setUrl(message.url as string);
        setHeight(message.height as number);
        // if (height !== event.data.height) {
        //   //
        //   console.log("height changed, triggering rendering");
        // } else {
        //   console.log("height not changed, should not trigger rendering");
        // }

        document.title = message.title;

        // console.log(
        //   "received loaded url: " + data.url + " height: " + data.height
        // );

        window.history.replaceState(
          null,
          message.title,
          window.location.origin + message.url
        );
      } else if (data.kind === "showContactPanel") {
        // console.log("showContactPanel");

        const message = data as OpenContactPanelMessage;

        openContactPanel(
          Number(message.contactId),
          Number(message.companyId),
          message.tabIndex
        );
      } else if (data.kind === "openWhatsappModal") {
        // console.log("showContactPanel");

        const message = data as OpenWhatsappModalMessage;

        openWhatsappModal(Number(message.contactId), message.quotationId);
      }
    },
    [setLoading, setUrl, setHeight, openContactPanel, openWhatsappModal]
  );

  const debounced = useDebounceCallback(onMessageReceived, 500);

  useEffect(() => {
    setLoading(true);
  }, [setLoading]);

  useEffect(() => {
    if (loading) {
      timeoutTimer.current = window.setTimeout(() => {
        if (loading) {
          setLoading(false);

          showErrorFeedback("Si è verificato un errore durante il caricamento");
        }
      }, 50000);
    } else {
      if (timeoutTimer.current) {
        clearTimeout(timeoutTimer.current);
        timeoutTimer.current = null;
      }
    }
  }, [loading, setLoading]);

  useEffect(() => {
    function messageHandler(event: MessageEvent<IFrameMessage>) {
      if (event.origin === import.meta.env.VITE_HD_LEGACY_URL) {
        debounced(event.data);
      }
    }

    // run useEffect only once
    // console.log("running effect! Add event listener");
    addEventListener("message", messageHandler);

    return () => {
      // console.log("remove event listener");
      removeEventListener("message", messageHandler);
    };
  }, [debounced]);

  return (
    <>
      {loading && <HdPageLoader></HdPageLoader>}
      <iframe
        width="100%"
        height={height > 0 ? height : "100%"}
        style={{ minHeight: "100%" }}
        referrerPolicy="origin"
        src={
          import.meta.env.VITE_HD_LEGACY_URL +
          location.pathname +
          location.hash +
          location.search
        }
      ></iframe>
    </>
  );
}

export default LegacyHdBridgePage;
