import { httpClient, SILENT_REQUEST_HEADER_KEY } from "@hd/http-client";
import { useQuery } from "@tanstack/react-query";

import { ChatMessage } from "../../../types/dto/contact/chat-message";

type QueryParams = {
  companyId: number;
  contactId: number | null;
};

const apiUrl = (params: QueryParams) =>
  `/contact/${params.contactId}/conversation-by-company/${params.companyId}`;

const fetchData = async (params: QueryParams): Promise<ChatMessage[]> => {
  const result = await httpClient.get<ChatMessage[]>(
    import.meta.env.VITE_HD_API_URL + apiUrl(params),
    { headers: { [SILENT_REQUEST_HEADER_KEY]: "true" } }
  );

  return result.data;
};

export const contactConversationQueryKey = (params: QueryParams) => [
  apiUrl(params),
];

export const useContactConversationQuery = (params: QueryParams) => {
  return useQuery({
    enabled: !!params.companyId,
    // staleTime: 10000 * 60,
    queryKey: contactConversationQueryKey(params),
    queryFn: () => fetchData(params),
  });
};
