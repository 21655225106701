import { httpClient, SILENT_REQUEST_HEADER_KEY } from "@hd/http-client";
import { useQuery } from "@tanstack/react-query";

type QueryParams = {
  contactId: number | null;
  companyId: number | null;
};

const apiUrl = (params: QueryParams) =>
  `/contact/${params.contactId}/is-online/${params.companyId}`;

const fetchData = async (params: QueryParams): Promise<boolean> => {
  const result = await httpClient.get<boolean>(
    import.meta.env.VITE_HD_API_URL + apiUrl(params),
    { headers: { [SILENT_REQUEST_HEADER_KEY]: "true" } }
  );

  return result.data;
};

export const contactIsOnlineQueryKey = (params: QueryParams) => [
  apiUrl(params),
];

export const useContactIsOnlineQuery = (params: QueryParams) => {
  return useQuery({
    // staleTime: 10000 * 60,
    queryKey: contactIsOnlineQueryKey(params),
    queryFn: () => fetchData(params),
    enabled: !!params.contactId && !!params.companyId,
  });
};
