import clsx from "clsx";
import { useCallback, useEffect, useState } from "react";
import {
  ComboBox,
  Input,
  Key,
  ListBox,
  ListBoxItem,
  ListBoxItemProps,
  Popover,
} from "react-aria-components";
import { FaSearch } from "react-icons/fa";
import { FaSpinner } from "react-icons/fa6";
import { MdClear } from "react-icons/md";
import { useDebounceCallback } from "usehooks-ts";

interface HdAutocompleteProps<T extends object> {
  children: (item: T) => React.ReactNode;
  getItemKey: (item: T) => Key;
  itemDescription: (item: T) => string;
  items?: Iterable<T>;
  isDataLoading?: boolean;
  // getItems?: (value: string) => Promise<Iterable<T>>;
  onInputChange?: (value: string) => void;
  onItemSelected?: (item: T | null) => void;
  selectedItem?: T;
  minLength?: number;
  disabled?: boolean;
  placeholder?: string;
}

function HdAutocomplete<T extends object>({
  children,
  items,
  // getItems,
  onItemSelected,
  selectedItem,
  minLength = 3,
  getItemKey,
  disabled,
  placeholder,
  itemDescription,
  onInputChange,
  isDataLoading,
}: HdAutocompleteProps<T>) {
  if (
    items === undefined
    // && getItems === undefined
  ) {
    throw new Error("HdAutocomplete: items or getItems is required");
  }
  // else if (items !== undefined && getItems !== undefined) {
  //   throw new Error(
  //     "HdAutocomplete: items and getItems are mutually exclusive"
  //   );
  // }

  const [filteredItems, setFilteredItems] = useState(items);
  const [typing, setTyping] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const search = useCallback(
    (text: string) => {
      // if (text !== searchText) {

      onInputChange?.(text);
      // }
      // if (getItems && !items) {
      //   const remoteItems = await getItems(text);
      //   setFilteredItems(remoteItems);
      //   setLoading(false);
      // }
    },
    [
      // getItems,
      // items,
      onInputChange,
    ]
  );
  const debouncedSearch = useDebounceCallback(search, 500);

  const onTextChange = useCallback(
    (text: string) => {
      setInputValue(text);
      setTyping(true);
      debouncedSearch(text);
      setFilteredItems([]);

      // if (text.length >= minLength) {
      // } else {
      //   // setFilteredItems([]);
      // }
    },
    [debouncedSearch]
  );

  const onSelectionCleared = useCallback(() => {
    onTextChange("");
    onItemSelected?.(null);

    // https://react-spectrum.adobe.com/react-aria/ComboBox.html#state
  }, [onItemSelected, onTextChange]);

  useEffect(() => {
    if (selectedItem) {
      setInputValue(itemDescription(selectedItem));
    } else {
      setInputValue("");
      setFilteredItems([]);
    }
  }, [selectedItem, itemDescription]);

  useEffect(() => {
    // console.log("items changed");
    setFilteredItems(items);
    setTyping(false);
  }, [items]);

  return (
    <ComboBox
      className={" bg-white outline-none border border-gray-300 text-[#333]"}
      items={filteredItems}
      onInputChange={(text) => onTextChange(text)}
      isDisabled={disabled}
      allowsEmptyCollection={true}
      menuTrigger="focus"
      inputValue={inputValue}
      onSelectionChange={(key) => {
        if (!filteredItems || !onItemSelected) return;

        const selectedItem = Array.from(filteredItems).find(
          (item) => getItemKey(item) === key
        );

        if (!selectedItem) return;

        setInputValue(itemDescription(selectedItem));
        onItemSelected(selectedItem);
      }}
    >
      <div className="relative">
        <Input
          className="outline-none pl-3 pr-6 py-2 w-full placeholder:text-gray-400"
          placeholder={placeholder}
          readOnly={selectedItem != null}
        />
        {/* <Button className={"px-3"}>
          <FaAngleDown className="text-gray-400 text-xxs ml-auto" />
        </Button> */}
        <div className="absolute inset-y-0 right-2 flex items-center">
          {isDataLoading || typing ? (
            <FaSpinner className="animate-spin"></FaSpinner>
          ) : selectedItem == null ? (
            <FaSearch className=""></FaSearch>
          ) : (
            <MdClear
              className="cursor-pointer"
              onClick={() => {
                onSelectionCleared();
              }}
            />
          )}
        </div>
      </div>
      <Popover
        className={
          "shadow-lg ring-1 ring-gray-200 entering:animate-in entering:fade-in entering:zoom-in-95 exiting:animate-out exiting:fade-out exiting:zoom-out-95 fill-mode-forwards origin-top-left"
        }
        style={{ width: "var(--trigger-width)" }}
      >
        <ListBox
          className={clsx(
            " bg-white outline-none text-sm overflow-hidden z-10"
          )}
          selectedKeys={selectedItem ? [getItemKey(selectedItem)] : []}
          renderEmptyState={() => (
            <div className="px-3 py-2">
              {inputValue.length < 3
                ? `Digita ${minLength} o più caratteri per ricercare.`
                : isDataLoading || typing
                ? "Caricamento..."
                : "Nessun risultato."}
            </div>
          )}
        >
          {children}
        </ListBox>
      </Popover>
    </ComboBox>
  );
}

HdAutocomplete.Item = function HdAutocompleteItem({
  children,
  ...props
}: ListBoxItemProps) {
  return <ListBoxItem {...props}>{children}</ListBoxItem>;
};

export default HdAutocomplete;
