import clsx from "clsx";
import { PropsWithChildren } from "react";

interface HdButtonProps {
  type: "button" | "submit";
  variant: "primary" | "secondary" | "accent" | "gray";
  size?: "small" | "medium" | "large";
  className?: string;
  onClick?: (e: React.MouseEvent) => void;
  disabled?: boolean;
}

function HdButton(props: PropsWithChildren<HdButtonProps>) {
  const getFontSize = () => {
    switch (props.size) {
      case "small":
        return "12px";
      case "medium":
        return "14px";
      case "large":
        return "16px";
      default:
        return "14px";
    }
  };
  return (
    <button
      type={props.type}
      style={{ fontSize: getFontSize() }}
      className={clsx(
        props.variant === "primary" && "bg-primary",
        props.variant === "accent" && "bg-accent",
        props.variant === "secondary" && "bg-secondary",
        props.variant === "gray" && "bg-gray-200 !text-bodyText",
        props.size === "small" && "px-3 py-2",
        (props.size == null || props.size === "medium") && "px-4 py-2 min-w-36",
        props.size === "large" && "px-8 py-4 min-w-36",
        props.disabled && "opacity-70 hover:!opacity-70 !cursor-default",
        "rounded-full cursor-pointer hover:opacity-90 focus-within:opacity-90 text-white outline-none",
        props.className
      )}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.children}
    </button>
  );
}
export default HdButton;
