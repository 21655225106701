import { useCallback, useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import { SectionItem } from '../../auth/types/section-item';
import { useSidebar } from './sidebar.store';

export type SidebarProps = {
  items: SectionItem[];
};

const getIcon = (sectionName: string) => {
  switch (sectionName) {
    case "Persone":
      return "fa-user";
    case "Contenuti":
      return "fa-file-o";
    case "Marketing":
      return "fa-envelope-o";
    case "Sales":
      return "fa-euro";
    case "Configura":
      return "fa-gear";
    case "Concierge":
      return "fa-info-circle";
    default:
      return "fa-user";
  }
};

function Sidebar(props: SidebarProps) {
  const [expandedRootSection, setExpandedRootSection] = useState<string | null>(
    null
  );

  const { isOpen, isClosing, isOpening, currentUrl, setCurrentUrl } =
    useSidebar();

  const location = useLocation();

  const isActive = useCallback(
    (item: SectionItem): boolean => {
      if (item.children && item.children.length > 0) {
        return item.children.some((child) => isActive(child));
      } else {
        return currentUrl.startsWith("/" + item.path);
      }
    },
    [currentUrl]
  );

  const isExpanded = useCallback(
    (rootSectionName: string): boolean => {
      return expandedRootSection === rootSectionName;
    },
    [expandedRootSection]
  );

  const expand = useCallback(
    (target: HTMLDivElement, rootSectionName: string): void => {
      const wrapperClassName = "wrapper";

      const wrapper = target.parentElement?.querySelector(
        "." + wrapperClassName
      ) as HTMLDivElement;

      if (isExpanded(rootSectionName)) {
        setExpandedRootSection(null);
        wrapper.style.height = "";
      } else {
        setExpandedRootSection(rootSectionName);
        const wrapper = target.parentElement?.querySelector(
          "." + wrapperClassName
        ) as HTMLDivElement;

        wrapper.style.height = wrapper.querySelector("ul")?.offsetHeight + "px";
      }
    },
    [isExpanded]
  );

  useEffect(() => {
    // set height of expanded root section
    if (expandedRootSection) {
      const wrapper = document.querySelector(
        ".wrapper.h-auto"
      ) as HTMLDivElement;
      if (wrapper) {
        wrapper.style.height = wrapper.querySelector("ul")?.offsetHeight + "px";
      }
    }
  }, [expandedRootSection]);

  useEffect(() => {
    setExpandedRootSection(
      props.items.find((item) => isActive(item))?.name || null
    );
  }, [isActive, props.items, currentUrl]);

  // aggiorno il currentUrl se viene cambiata la navigazione
  useEffect(() => {
    setCurrentUrl(location.pathname + location.hash + location.search);
  }, [location, setCurrentUrl]);

  return (
    <div
      className={
        "sidebar flex-shrink-0 font-thin w-48 text-white bg-secondary absolute top-0 bottom-0 sm:static" +
        (isOpen ? "" : " closed ") +
        (isClosing ? " closing " : "") +
        (isOpening ? " opening " : "")
      }
    >
      <ul>
        {props.items.map((rootSectionItem) => (
          <li
            className={
              "root-nav-item relative group bg-secondary" +
              (isActive(rootSectionItem) ? " active " : "") +
              (isExpanded(rootSectionItem.name) ? " expanded" : "") +
              (isOpen ? "" : " hover:w-56 hover:shadow-md")
            }
            key={rootSectionItem.name}
          >
            <div
              className="px-4 py-6 flex items-center text-md"
              onClick={(ev) =>
                isOpen && expand(ev.currentTarget, rootSectionItem.name)
              }
            >
              <span className={isOpen ? "block" : `hidden group-hover:block`}>
                {rootSectionItem.name}
              </span>
              <span
                className={
                  "icon transition-all duration-300 fa fa-angle-down ml-auto text-base text-[#677a8c] group-hover:text-[#8cacc3] " +
                  (!isExpanded(rootSectionItem.name) ? "rotate-90" : "rotate-0")
                }
              ></span>
              <i
                className={
                  isOpen
                    ? "hidden"
                    : "group-hover:!hidden fa " + getIcon(rootSectionItem.name)
                }
              ></i>
            </div>
            <div
              className={
                "wrapper " +
                (isOpen
                  ? " block " +
                    (isExpanded(rootSectionItem.name)
                      ? "h-auto opacity-100"
                      : "!h-0 overflow-hidden opacity-0")
                  : " hidden group-hover:block absolute left-12 right-0 group-hover:shadow-md")
              }
            >
              <ul className="bg-white text-slate-800 ">
                {rootSectionItem.children?.map((sectionItem) => (
                  <li key={sectionItem.name}>
                    {sectionItem.children?.length ? (
                      <div className="relative">
                        <div
                          className={
                            "child-nav-item px-4 py-3 peer peer-hover:bg-bodyBg" +
                            (isActive(sectionItem) ? " active" : "")
                          }
                        >
                          {sectionItem.name}
                        </div>
                        <ul className="absolute top-0 py-3 left-full bg-bodyBg text-slate-800 hidden peer-hover:block hover:block peer">
                          {sectionItem.children.map((childSectionItem) => (
                            <li key={childSectionItem.name}>
                              <NavLink
                                className="block px-6 py-2 text-nowrap w-full hover:underline"
                                to={childSectionItem.path || "/"}
                              >
                                {childSectionItem.name}
                              </NavLink>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ) : (
                      <NavLink
                        className={
                          "block px-4 py-3  child-nav-item" +
                          (isActive(sectionItem) ? " active " : "")
                        }
                        to={sectionItem.path || "/"}
                      >
                        {sectionItem.name}
                      </NavLink>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Sidebar;
