import clsx from "clsx";
import { lazy, Suspense, useCallback, useState } from "react";

import { useI18n } from "@hd/i18n";
import { HdLoader, HdOffcanvas, HdTabs } from "@hd/ui-kit";
import { useQueryClient } from "@tanstack/react-query";

import { useMeQuery } from "../../api/auth/useMeQuery";
import { contactConversationQueryKey } from "../../api/contacts/query/useContactConversationQuery";
import { ContactConnectionInfo } from "../../types/model/shared/contact-connection-info";
import { SignalrChatMessage } from "../../types/model/shared/signalr-chat-message";
import { ChatRoomStatus } from "../chat/chat-room-status";
import { useChatStore } from "../chat/chat.store";
import useHubConnection from "../chat/useHubConnection";
import ContactPanelHeader from "./components/ContactPanelHeader";
// import DefaultMessagesPanel from "./components/DefaultMessagesPanel";
import { useContactPanelStore } from "./contact-panel.store";

function ContactPanel() {
  const queryClient = useQueryClient();

  const isOpen = useContactPanelStore((state) => state.isOpen);
  const close = useContactPanelStore((state) => state.close);
  const contactId = useContactPanelStore((state) => state.contactId);
  const selectedTabId = useContactPanelStore((state) => state.selectedTabId);
  const changeTab = useContactPanelStore((state) => state.changeTab);
  const companyId = useContactPanelStore((state) => state.companyId);
  const isSubPanelOpen = useContactPanelStore((state) => state.isSubPanelOpen);

  const setChatRoomStatus = useChatStore((state) => state.setChatRoomStatus);

  const t = useI18n();

  const { data: userData } = useMeQuery();

  const [isOnline, setIsOnline] = useState<boolean | null>(null);

  const ActivityHistoryPanel = lazy(
    () => import("./components/ActivityHistoryPanel")
  );

  const ConversationPanel = lazy(
    () => import("./components/ConversationPanel")
  );

  const ContactCardPanel = lazy(() => import("./components/ContactCardPanel"));

  const DefaultMessagesPanel = lazy(
    () => import("./components/DefaultMessagesPanel")
  );

  const onNewMessage = useCallback(
    (message: SignalrChatMessage) => {
      queryClient.invalidateQueries({
        queryKey: contactConversationQueryKey({
          contactId: Number(message.GroupName.split("-")[1]),
          companyId: Number(message.GroupName.split("-")[0]),
        }),
      });
    },
    [queryClient]
  );

  const onPersonStatusUpdated = useCallback(
    (data: ContactConnectionInfo) => {
      if (Number(data.PersonId) === contactId) {
        setIsOnline(!data.IsClosed);
      }
    },
    [setIsOnline, contactId]
  );

  const onStatusChanged = useCallback(
    (data: ChatRoomStatus) => {
      setChatRoomStatus(`c${companyId}`, data);
    },
    [setChatRoomStatus, companyId]
  );

  useHubConnection(
    companyId,
    userData?.username || null,
    onNewMessage,
    onPersonStatusUpdated,
    onStatusChanged
  );

  return (
    <HdOffcanvas
      isOpen={isOpen}
      position="right"
      onClose={close}
      backdrop={true}
    >
      {contactId && companyId && (
        <div className="flex h-full text-bodyText text-xs relative">
          <div
            style={{ width: "300px" }}
            className={clsx(
              "bg-white absolute outline-none transition transform duration-300 ease-in-out flex flex-col shadow-xl z-40 left-0 top-0 bottom-0",
              isSubPanelOpen ? "translate-x-[-100%]" : "translate-x-0"
            )}
          >
            <Suspense fallback={<HdLoader />}>
              <DefaultMessagesPanel
                companyId={companyId}
                contactId={contactId}
              ></DefaultMessagesPanel>
            </Suspense>
          </div>
          <div
            style={{ width: "600px" }}
            className="flex flex-col shadow-xl z-50 bg-white"
          >
            {companyId && contactId && userData && (
              <ContactPanelHeader
                companyId={companyId}
                contactId={contactId}
                username={userData.username}
                isOnlineRT={isOnline}
              ></ContactPanelHeader>
            )}
            <HdTabs
              className="flex-grow mt-5 flex flex-col"
              selectedIndex={selectedTabId}
              onChange={changeTab}
            >
              <HdTabs.Header className="-mb-[1px]">
                <HdTabs.Tab className="data-[selected]:border-l data-[selected]:border-r ml-7 relative">
                  {t("contactPanel.conversationTab")}
                </HdTabs.Tab>
                <HdTabs.Tab className="data-[selected]:border-l data-[selected]:border-r relative">
                  {t("contactPanel.activityHistoryTab")}
                </HdTabs.Tab>
                <HdTabs.Tab className="data-[selected]:border-l data-[selected]:border-r relative">
                  {t("contactPanel.contactCardTab")}
                </HdTabs.Tab>
              </HdTabs.Header>
              {contactId && companyId && (
                <HdTabs.Content className="border-t flex-grow flex flex-col">
                  <HdTabs.Panel className=" flex-grow flex flex-col">
                    <Suspense fallback={<HdLoader />}>
                      <ConversationPanel
                        contactId={contactId}
                        companyId={companyId}
                      ></ConversationPanel>
                    </Suspense>
                  </HdTabs.Panel>
                  <HdTabs.Panel className="ml-3 flex-grow flex flex-col">
                    <Suspense fallback={<HdLoader />}>
                      <ActivityHistoryPanel
                        contactId={contactId}
                        companyId={companyId}
                      ></ActivityHistoryPanel>
                    </Suspense>
                  </HdTabs.Panel>
                  <HdTabs.Panel className="ml-3 flex-grow flex flex-col">
                    <Suspense fallback={<HdLoader />}>
                      <ContactCardPanel
                        companyId={companyId}
                        contactId={contactId}
                      ></ContactCardPanel>
                    </Suspense>
                  </HdTabs.Panel>
                </HdTabs.Content>
              )}
            </HdTabs>
          </div>
        </div>
      )}
    </HdOffcanvas>
  );
}

export default ContactPanel;
