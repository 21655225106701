import clsx from "clsx";
import React, { useState } from "react";
import { Label, Radio, RadioGroup } from "react-aria-components";

interface HdRadioGroupProps {
  children?: React.ReactNode;
  className?: string;
  label?: string;
  description?: string;
  onChange?: (value: string) => void;
  selected?: string | null;
}

function HdRadioGroup({
  label,
  children,
  selected,
  onChange,
}: HdRadioGroupProps) {
  const [innerSelected, setInnerSelected] = useState<string | null>(
    selected || null
  );

  return (
    <RadioGroup
      value={innerSelected}
      onChange={(value) => {
        setInnerSelected(value);
        onChange?.(value);
      }}
      className={"flex flex-col gap-2"}
    >
      <Label>{label}</Label>
      {children}
    </RadioGroup>
  );
}

HdRadioGroup.Radio = (props: {
  value: string;
  children?: React.ReactNode;
  label: string;
  className?: string;
}) => {
  return (
    <Radio
      className={"flex items-center group cursor-pointer"}
      value={props.value}
    >
      <div
        className={clsx(
          "w-5 h-5 transition-all duration-200 border-2 border-gray-400 rounded-full mr-2 group-data-[selected]:border-primary group-data-[selected]:border-[0.5rem]"
        )}
      ></div>
      <div>{props.label}</div>
    </Radio>
  );
};

export default HdRadioGroup;
