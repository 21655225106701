import clsx from "clsx";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FaAngleDown } from "react-icons/fa6";

import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";

import { useMeQuery } from "../../../api/auth/useMeQuery";
import { ListItem } from "../../../types/model/shared/list-item";

interface CompanySelectorProps {
  selectedCompanyId: number | null;
  selectedCompanyChanged: (companyId: number) => void;
}

function CompanySelector({
  selectedCompanyChanged,
  selectedCompanyId,
}: CompanySelectorProps) {
  // const companies = useAuth((s) => s.loggedUserData?.activeCompanies || []);

  const { data } = useMeQuery();
  const companies = useMemo(() => data?.activeCompanies || [], [data]);

  const [innerSelectedCompany, setInnerSelectedCompany] =
    useState<ListItem | null>(null);

  const onChangeHandler = useCallback(
    (company: ListItem) => {
      selectedCompanyChanged(company.id);
    },
    [selectedCompanyChanged]
  );

  useEffect(() => {
    let company = companies.find((c) => c.id === selectedCompanyId);

    // se non ho una company selezionata all'inizio, seleziono la prima e scateno l'evento di change per informare il padre
    if (!company) {
      company = companies[0];
    }
    setInnerSelectedCompany(company);
    onChangeHandler(company);
  }, [companies, selectedCompanyId, setInnerSelectedCompany, onChangeHandler]);

  return (
    <Listbox value={innerSelectedCompany} onChange={onChangeHandler}>
      <ListboxButton className={"font-bold flex items-center py-1 "}>
        <span className="mr-3">{innerSelectedCompany?.name}</span>
        <FaAngleDown className="ml-auto text-gray-400 text-xxs" />
      </ListboxButton>
      <ListboxOptions
        transition
        anchor="bottom start"
        className={clsx(
          "bg-white shadow-lg ring-1 ring-gray-200 z-10",
          "transition duration-200 ease-out data-[closed]:opacity-0"
        )}
      >
        {companies.map((company) => (
          <ListboxOption
            key={company.id}
            value={company}
            className={clsx(
              "company-option text-sm text-bodyText px-3 py-2 cursor-pointer data-[selected]:!bg-primary data-[selected]:text-black hover:bg-gray-100 hover:text-black"
            )}
            onClick={(e) => e.stopPropagation()}
          >
            {company.name}
          </ListboxOption>
        ))}
      </ListboxOptions>
    </Listbox>
  );
}

export default CompanySelector;
