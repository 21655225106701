import { httpClient, SILENT_REQUEST_HEADER_KEY } from "@hd/http-client";
import { useQuery } from "@tanstack/react-query";

type QueryParams = {
  contactId: number | null;
};

const apiUrl = (params: QueryParams) =>
  `/contact/${params.contactId}/phone-numbers`;

const fetchData = async (params: QueryParams): Promise<string[]> => {
  const result = await httpClient.get<string[]>(
    import.meta.env.VITE_HD_API_URL + apiUrl(params),
    { headers: { [SILENT_REQUEST_HEADER_KEY]: "true" } }
  );

  return result.data;
};

export const contactPhoneNumbersQueryKey = (params: QueryParams) => [
  apiUrl(params),
];

export const useContactPhoneNumbersQuery = (params: QueryParams) => {
  return useQuery({
    // staleTime: 10000 * 60,
    queryKey: contactPhoneNumbersQueryKey(params),
    queryFn: () => fetchData(params),
    enabled: !!params.contactId,
  });
};
