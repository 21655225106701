import { useMemo } from 'react';
import ReactCountryFlag from 'react-country-flag';

import { useI18n } from '@hd/i18n';
import { HdLoader, HdOnlineLed } from '@hd/ui-kit';

import { useContactDetailsQuery } from '../../../api/contacts/query/useContactDetailsQuery';
import { useContactIsOnlineQuery } from '../../../api/contacts/query/useContactIsOnlineQuery';
import { formatDate } from '../../../utils/dates';

export interface ContactPanelHeaderProps {
  contactId: number;
  companyId: number;
  username: string;
  isOnlineRT: boolean | null;
}

function ContactPanelHeader({
  contactId,
  companyId,
  isOnlineRT,
}: ContactPanelHeaderProps) {
  const { isPending: contactDetailsPending, data: contactDetails } =
    useContactDetailsQuery({ contactId });

  const { isPending: isOnlinePending, data: isOnlineQuery } =
    useContactIsOnlineQuery({ contactId, companyId });

  const isOnline = useMemo(
    () => (isOnlineRT != null ? isOnlineRT : isOnlineQuery),
    [isOnlineQuery, isOnlineRT]
  );

  const t = useI18n();

  return (
    <div className="pt-8 px-7 min-h-32">
      {contactDetailsPending || !contactDetails ? (
        <HdLoader></HdLoader>
      ) : (
        <>
          <h2 className="flex items-center text-sm font-bold">
            {contactDetails.countryCode && (
              <ReactCountryFlag
                className="mr-2"
                countryCode={contactDetails.countryCode.toUpperCase()}
                svg
              />
            )}
            <span>
              {contactDetails.name} {contactDetails.surname}
            </span>
            {/* <MdClose
          className="ml-auto text-xl cursor-pointer"
          onClick={close}
        ></MdClose> */}
          </h2>
          <div className="mt-1 font-medium">
            {!isOnlinePending && isOnline != null && (
              <div className="flex items-center">
                <span>utente {isOnline ? "online" : "offline"} ora</span>
                <HdOnlineLed className="ms-1" online={isOnline}></HdOnlineLed>
              </div>
            )}
            <div>
              Ultimo soggiorno:
              {contactDetails.lastStayInfo && (
                <a className="cursor-pointer text-accent font-bold mx-1">
                  {contactDetails.lastStayInfo.companies.join(", ")},{" "}
                  {formatDate(
                    new Date(contactDetails.lastStayInfo.checkInDate || "")
                  )}{" "}
                  -
                  {formatDate(
                    new Date(contactDetails.lastStayInfo.checkOutDate || "")
                  )}{" "}
                  (
                  {t("common.nightsCount", {
                    count: contactDetails.lastStayInfo.stayNights,
                  })}
                  ) ,{" "}
                  {t("common.adultsCount", {
                    count: contactDetails.lastStayInfo.adultsCount,
                  })}{" "}
                  {t("common.childrenCount", {
                    count: contactDetails.lastStayInfo.childrenCount,
                  })}
                </a>
              )}
            </div>
            <div>
              Contatti:
              {contactDetails.email && (
                <a className="cursor-pointer text-accent font-bold mx-1">
                  {contactDetails.email}
                </a>
              )}
              -
              {contactDetails.mobile && (
                <a className="cursor-pointer text-accent font-bold mx-1">
                  {contactDetails.mobile}
                </a>
              )}
              -
              {contactDetails.phone && (
                <a className="cursor-pointer text-accent font-bold mx-1">
                  {contactDetails.phone}
                </a>
              )}
            </div>
            <div>Note interne: {contactDetails.notes}</div>
          </div>
        </>
      )}
    </div>
  );

  //   return ({
  //         contactDetailsPending ? <HdLoader></HdLoader> : (<div className="pt-8 px-7">

  //     </div>)}
  //   );
}

export default ContactPanelHeader;
