import { create } from "zustand";

export interface WhatsappModalState {
  isOpen: boolean;
  contactId: number | null;
  quotationId: string | null;
  open(contactId: number, quotationId: string | null): void;
  close(): void;
}

export const useWhatsappModal = create<WhatsappModalState>((set) => {
  return {
    isOpen: false,
    contactId: null,
    quotationId: null,
    open: (contactId: number, quotationId: string | null) =>
      set(() => ({
        isOpen: true,
        contactId,
        quotationId,
      })),

    close: () =>
      set(() => ({
        isOpen: false,
        contactId: null,
        quotationId: null,
      })),
  };
});
