import axios, {
  AxiosError,
  AxiosInstance,
  InternalAxiosRequestConfig,
} from "axios";
import { PropsWithChildren, useEffect } from "react";

import { REFRESH_TOKEN_URL, refreshToken } from "../../core/auth/auth.api";
import { getToken, setToken } from "../../core/auth/auth.functions";
import { useAuth } from "../../core/auth/auth.store";
import { useLoader } from "../../core/loader/loader.store";
import { SILENT_REQUEST_HEADER_KEY } from "./constants";
import httpClient from "./http-client";
import { ApiError, AxiosAuthRefreshCache } from "./types";

const cache: AxiosAuthRefreshCache = {
  // skipInstances: [],
  refreshCall: undefined,
  requestQueueInterceptorId: undefined,
};

function createRequestQueueInterceptor(
  instance: AxiosInstance,
  cache: AxiosAuthRefreshCache
): number {
  if (typeof cache.requestQueueInterceptorId === "undefined") {
    cache.requestQueueInterceptorId = instance.interceptors.request.use(
      (request) => {
        return (cache.refreshCall as Promise<unknown>)
          .then(() => request)
          .catch(() => {
            throw new axios.Cancel("Request call failed");
          });
      }
    );
  }
  return cache.requestQueueInterceptorId;
}

function createRefreshCall(
  error: AxiosError,
  fn: (error: AxiosError) => Promise<unknown>,
  cache: AxiosAuthRefreshCache
): Promise<unknown> {
  if (!cache.refreshCall) {
    cache.refreshCall = fn(error);
  }
  return cache.refreshCall;
}

function unsetCache(
  instance: AxiosInstance,
  cache: AxiosAuthRefreshCache
): void {
  if (cache.requestQueueInterceptorId)
    instance.interceptors.request.eject(cache.requestQueueInterceptorId);
  cache.requestQueueInterceptorId = undefined;
  cache.refreshCall = undefined;
}

async function refreshCall() {
  const result = await refreshToken();
  setToken(result.jwtToken);
}
/**rvi
 * Extracts the error message from the given error, if present.
 * @param {unknown} error The error to extract the message from.
 * @returns {string} The error message if present, otherwise "Si  verificato un errore imprevisto".
 */
const getApiErrorMessage = (error: unknown) => {
  const defaultMsg = "Si è verificato un errore imprevisto";
  if (error instanceof AxiosError) {
    if (error.status === 500) {
      return defaultMsg;
    }
    if (error.response?.data) {
      if (typeof error.response.data === "string") {
        return error.response.data;
      } else if (typeof error.response.data === "object") {
        if (error.response.data.error) {
          return error.response.data.error;
        } else if (error.response.data.message) {
          return error.response.data.message;
        }
      }
    }
  } else if (error instanceof Error) {
    return error.message;
  }

  return defaultMsg;
};

const throwErrorAndLog = (error: AxiosError) => {
  console.error("API Error: ", error);
  const message = getApiErrorMessage(error);
  const apiError: ApiError = {
    name: error.name,
    message,
    status: error.status,
  };
  return Promise.reject(apiError);
};

export const HttpClientInterceptorProvider = (props: PropsWithChildren) => {
  // const token = useAuth((state) => state.token);
  // const setToken = useAuth((state) => state.setToken);
  const sessionExpired = useAuth((state) => state.sessionExpired);

  const { addLoader, removeLoader } = useLoader();

  useEffect(() => {
    const requestInterceptor = (config: InternalAxiosRequestConfig) => {
      if (!config.headers.get(SILENT_REQUEST_HEADER_KEY)) {
        addLoader();
      }
      //configuring header
      const token = getToken();
      if (token && !config.headers.Authorization) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      return config;
    };

    // const requestErrorInterceptor = (error: unknown) => Promise.reject(error);

    const responseErrorInterceptor = (error: AxiosError) => {
      if (
        error.config?.url !== REFRESH_TOKEN_URL &&
        error.response &&
        error.response.status === 401
      ) {
        const refreshPromise = createRefreshCall(error, refreshCall, cache);

        createRequestQueueInterceptor(httpClient, cache);

        return refreshPromise
          .catch((refreshError: AxiosError) => {
            sessionExpired();
            return throwErrorAndLog(refreshError);
          })
          .then(() => {
            const token = getToken();
            const originalRequest = error.config as InternalAxiosRequestConfig;
            originalRequest.headers.Authorization = `Bearer ${token}`;
            return axios(originalRequest as InternalAxiosRequestConfig);
          })
          .finally(() => {
            if (
              error.config &&
              !error.config.headers.get(SILENT_REQUEST_HEADER_KEY)
            ) {
              removeLoader();
            }
            unsetCache(httpClient, cache);
          });
      }

      if (
        error.config &&
        !error.config.headers.get(SILENT_REQUEST_HEADER_KEY)
      ) {
        removeLoader();
      }

      return throwErrorAndLog(error);
    };

    const requestInterceptorId = httpClient.interceptors.request.use(
      requestInterceptor
      // (error: unknown) => Promise.reject(error)
    );

    const responseInterceptorId = httpClient.interceptors.response.use(
      (response) => {
        if (
          response.config &&
          !response.config.headers.get(SILENT_REQUEST_HEADER_KEY)
        ) {
          removeLoader();
        }
        return response;
      },
      responseErrorInterceptor
    );

    return () => {
      httpClient.interceptors.request.eject(requestInterceptorId);
      httpClient.interceptors.response.eject(responseInterceptorId);
    };
  }, [addLoader, removeLoader, sessionExpired]);

  return props.children;
};
