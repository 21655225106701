import { create } from "zustand";
import { devtools } from "zustand/middleware";

import { ChatRoomStatus } from "./chat-room-status";

export interface ChatState {
  chatRoomStatus: { [chatroomId: string]: ChatRoomStatus };
  setChatRoomStatus: (chatroomId: string, status: ChatRoomStatus) => void;
  // sendMessage(
  //   companyId: string,
  //   username: string,
  //   operatorName: string,
  //   message: string,
  //   destId: string
  // ): Promise<void>;

  // onlineContactIds: number[];
  // onOnlinePerson(contactId: number): void;
  // onOfflinePerson(contactId: number): void;
  // onlineOperators: string[];
  // onOnlineOperator(username: string): void;
  // onOfflineOperator(username: string): void;
  // getOnlinePersons(companyId: number): Promise<void>;
}

export const useChatStore = create<ChatState>()(
  devtools((set, get) => {
    return {
      chatRoomStatus: {},
      setChatRoomStatus: (chatroomId: string, status: ChatRoomStatus) => {
        const d = { ...get().chatRoomStatus };
        d[chatroomId] = status;
        set(() => ({
          chatRoomStatus: d,
        }));
      },
      // onlineContactIds: [],
      // onlineOperators: [],
      // getOnlinePersons: async (companyId: number) => {
      //   const result = await httpClient.get<number[]>(
      //     import.meta.env.VITE_HD_API_URL +
      //       "/conversationhub/online-contact-ids",
      //     {
      //       params: {
      //         companyId,
      //       },
      //     }
      //   );
      //   if (result.status >= 400) {
      //     throw new Error(result.statusText);
      //   }
      //   set(() => ({
      //     onlineContactIds: result.data,
      //   }));

      //   const resultOperators = await httpClient.get<string[]>(
      //     import.meta.env.VITE_HD_API_URL + "/conversationhub/online-operators",
      //     {
      //       params: {
      //         companyId,
      //       },
      //     }
      //   );
      //   if (resultOperators.status >= 400) {
      //     throw new Error(resultOperators.statusText);
      //   }
      //   set(() => ({
      //     onlineOperators: resultOperators.data,
      //   }));
      // },
      // onOnlinePerson: (contactId: number) => {
      //   const onlineContactIds = [
      //     ...new Set([...get().onlineContactIds, contactId]),
      //   ];
      //   set(() => ({
      //     onlineContactIds,
      //   }));
      // },
      // onOfflinePerson: (contactId: number) => {
      //   const onlineContactIds = get().onlineContactIds.filter(
      //     (c) => c !== contactId
      //   );
      //   set(() => ({
      //     onlineContactIds,
      //   }));
      // },
      // onOnlineOperator: (username: string) => {
      //   const onlineOperators = [
      //     ...new Set([...get().onlineOperators, username]),
      //   ];
      //   set(() => ({
      //     onlineOperators,
      //   }));
      // },
      // onOfflineOperator: (username: string) => {
      //   const onlineOperators = get().onlineOperators.filter(
      //     (c) => c !== username
      //   );
      //   set(() => ({
      //     onlineOperators,
      //   }));
      // },
      // sendMessage: async (
      //   companyId: string,
      //   username: string,
      //   operatorName: string,
      //   message: string,
      //   personId: string
      // ) => {
      //   const hubName = `c${companyId}`;
      //   const userId = `${companyId}~${username}~${1}`;
      //   const result = await axios.post(
      //     `${
      //       import.meta.env.VITE_HD_CHAT_OPERATOR_URL
      //     }/api/send?userId=${userId}&hub=${hubName}&destId=${companyId}~${personId}~${1}`,
      //     {
      //       senderfullname: operatorName,
      //       text: message,
      //     }
      //   );

      //   if (result.status >= 400) {
      //     throw new Error(result.statusText);
      //   }
      // },
    };
  })
);
