import { create } from "zustand";

export interface ContactPanelState {
  isOpen: boolean;
  isSubPanelOpen: boolean;
  contactId: number | null;
  companyId: number | null;
  selectedTabId: number;
  presetMessage: string | null;
  open(contactId: number, companyId: number, selectedTabId: number): void;
  close(): void;
  changeTab(selectedTabId: number): void;
  toggleSubPanel(): void;
  selectPresetMessage(text: string): void;
}

export const useContactPanelStore = create<ContactPanelState>((set) => {
  return {
    isOpen: false,
    isSubPanelOpen: false,
    contactId: null,
    companyId: null,
    selectedTabId: 0,
    presetMessage: null,
    open: (contactId: number, companyId: number, selectedTabId: number) =>
      set(() => ({
        isOpen: true,
        isSubPanelOpen: false,
        contactId,
        companyId,
        selectedTabId,
      })),
    toggleSubPanel: () =>
      set((s) => ({ isSubPanelOpen: !s.isSubPanelOpen, presetMessage: null })),
    close: () =>
      set(() => ({
        isOpen: false,
        isSubPanelOpen: false,
        presetMessage: null,
      })),
    changeTab: (selectedTabId: number) => set(() => ({ selectedTabId })),
    selectPresetMessage: (text: string) => set(() => ({ presetMessage: text })),
  };
});
