import axios from "axios";
import { useEffect } from "react";

import {
  HubConnection,
  HubConnectionBuilder,
  LogLevel,
} from "@microsoft/signalr";

import { ContactConnectionInfo } from "../../types/model/shared/contact-connection-info";
import { SignalrChatMessage } from "../../types/model/shared/signalr-chat-message";
import { ChatRoomStatus } from "./chat-room-status";

export interface ChatConnection {
  connection: HubConnection;
  connect: () => Promise<string>;
  disconnect: () => Promise<string>;
  onConnected: (cb: (hubName: string) => void) => void;
  onDisconnected: (cb: (hubName: string) => void) => void;
  onReconnecting: (cb: (hubName: string) => void) => void;
  onNewMessage: (cb: (message: SignalrChatMessage) => void) => void;
  onPersonStatusUpdated: (cb: (message: ContactConnectionInfo) => void) => void;
}

export default function useHubConnection(
  companyId: number | null,
  username: string | null,
  onNewMessage?: (message: SignalrChatMessage) => void,
  onPersonStatusUpdated?: (message: ContactConnectionInfo) => void,
  onStatusChanged?: (status: ChatRoomStatus) => void
) {
  // console.log("connecting to hub " + hubName);

  // const [chatConnection, setChatConnection] = useState<ChatConnection | null>(
  //   null
  // );
  // const [onNewMsg, setOnNewMsg] = useState<
  //   ((message: SignalrChatMessage) => void) | null
  // >();

  useEffect(() => {
    if (companyId && username) {
      const hubName = `c${companyId}`;
      const userId = `${companyId}~${username}~${1}`;

      const connection = new HubConnectionBuilder()
        .withUrl(
          import.meta.env.VITE_HD_CHAT_OPERATOR_URL +
            `/api?userId=${userId}&hub=${hubName}`
        )
        .withAutomaticReconnect()
        .configureLogging(LogLevel.Information)
        .build();

      const startConnection = async () => {
        try {
          // if (connection.state === "Connected") return;

          await connection.start();

          await axios.post(
            `${
              import.meta.env.VITE_HD_CHAT_OPERATOR_URL
            }/api/operators/join?userId=${userId}&hub=${hubName}`
          );
        } catch (e) {
          console.error(e);
        }

        onStatusChanged?.(ChatRoomStatus.Connected);
        if (onNewMessage) {
          connection.on("newMessage", onNewMessage);
        }
        if (onPersonStatusUpdated) {
          connection.on("personStatusUpdated", onPersonStatusUpdated);
        }
        if (onStatusChanged) {
          connection.onreconnected(() =>
            onStatusChanged(ChatRoomStatus.Connected)
          );
          connection.onreconnecting(() =>
            onStatusChanged(ChatRoomStatus.Connecting)
          );
          connection.onclose(() =>
            onStatusChanged(ChatRoomStatus.Disconnected)
          );
        }
        return hubName;
      };

      const stopConnection = async () => {
        await connection.stop();

        return hubName;
      };

      // let onNewMsgCb = (message: SignalrChatMessage) => {};

      // const _chatConn: ChatConnection = {
      //   connection: connection,
      //   connect: startConnection,
      //   disconnect: stopConnection,
      //   onConnected: (cb: (hubName: string) => void) => {
      //     onStatusChanged?.(ChatRoomStatus.Connected);
      //     if (onNewMessage) {
      //       connection.on("newMessage", onNewMessage);
      //     }
      //     if (onPersonStatusUpdated) {
      //       connection.on("personStatusUpdated", onPersonStatusUpdated);
      //     }
      //     if (onStatusChanged) {
      //       connection.onreconnected(() =>
      //         onStatusChanged(ChatRoomStatus.Connected)
      //       );
      //       connection.onreconnecting(() =>
      //         onStatusChanged(ChatRoomStatus.Connecting)
      //       );
      //       connection.onclose(() =>
      //         onStatusChanged(ChatRoomStatus.Disconnected)
      //       );
      //     }
      //   },
      //   onDisconnected: (cb: (hubName: string) => void) => {
      //     connection.off("newMessage");
      //     connection.off("personStatusUpdated");
      //     connection.onclose(() => cb(hubName));
      //   },
      //   onReconnecting: (cb: (hubName: string) => void) => {
      //     connection.onreconnecting(() => cb(hubName));
      //   },
      //   onNewMessage: (cb: (message: SignalrChatMessage) => void) => {
      //     connection.on("newMessage", (message: SignalrChatMessage) => {
      //       cb(message);
      //     });
      //   },
      //   onPersonStatusUpdated: (
      //     cb: (message: ContactConnectionInfo) => void
      //   ) => {
      //     connection.on(
      //       "personStatusUpdated",
      //       (message: ContactConnectionInfo) => {
      //         cb(message);
      //       }
      //     );
      //   },
      // };

      // setChatConnection(_chatConn);

      startConnection().then((hubName) => {
        console.log("connected", hubName);
      });

      return () => {
        stopConnection().then((hubName) => {
          console.log("disconnected", hubName);
        });
      };
    }
  }, [
    companyId,
    username,
    onNewMessage,
    onPersonStatusUpdated,
    onStatusChanged,
  ]);

  // return chatConnection;
}
